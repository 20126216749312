import {
    PasswordListProps,
    PasswordSnackbarState
} from 'src/index.d';
import React, {
    useState
} from 'react';
import {
    Box,
    Stack,
    Divider,
    Snackbar,
    Alert,
    Typography
} from '@mui/material';
import PasswordRow from 'src/components/PasswordRow/PasswordRow';


const PasswordList = (props: PasswordListProps) => {

    // gets properties
    const { passwords, refresh, setPasswords } = props;

    // uses states
    const [snackbarState, setSnackbarState] = useState<PasswordSnackbarState>({ open: false });

    // handles close event of snackbar
    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarState(prev => ({ ...prev, open: false }));
    };

    return (
        <Box>
            <Stack sx={{
                m: 0,
                p: 0
            }}>
                {
                    passwords.map((password, index) => {
                        if (index !== passwords.length - 1) {
                            return [
                                <PasswordRow
                                    key={index}
                                    index={index}
                                    passwords={passwords}
                                    refresh={refresh}
                                    setPasswords={setPasswords}
                                    setSnackbarState={setSnackbarState}
                                />,
                                <Divider
                                    key={`${index}-hr`}
                                    sx={{ my: '10px' }}>
                                </Divider>
                            ];
                        }
                        return <PasswordRow
                            key={index}
                            index={index}
                            passwords={passwords}
                            refresh={refresh}
                            setPasswords={setPasswords}
                            setSnackbarState={setSnackbarState}
                        />;
                    })
                }
            </Stack>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                autoHideDuration={3000}
                open={snackbarState.open}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    sx={{ width: '100%' }}>
                    <Typography sx={{
                        maxWidth: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {`Copied "${snackbarState.password}"`}
                    </Typography>
                </Alert>
            </Snackbar>
        </Box>
    );
}


export default PasswordList;
