import {
    PasswordNumberPosition,
    PasswordGenerationOption
} from 'src/index.d';
import bipList from 'src/assets/bipList.json';
import symbolList from 'src/assets/symbolList.json';


/**
 * Generates a zero array of given range
 *
 * @param length - The length of array
 * @returns The zero array
 */
export const zeroArray = (length: number): Array<0> => {
    const arr: Array<0> = [];
    for (let i = 0; i < length; i++) {
        arr.push(0);
    }
    return arr;
}


/**
 * Removes redundant space on a string
 *
 * @param str - The target string
 * @returns - The string removed redundant spaces
 */
export const removeRedundantInputSpace = (str: string): string => {
    if (str.endsWith(' ')) {
        return `${str.replace(/\s+/g, ' ').trim()} `;
    }
    return str.replace(/\s+/g, ' ').trim();
}


/**
 * Removes repetitive characters on a string
 *
 * @param str - The target string
 * @returns - The removes repetitive characters
 */
export const removeRepetitiveCharacter = (str: string): string => {
    const charSet = new Set<string>();
    let result = '';

    for (const char of str) {
        if (!charSet.has(char)) {
            charSet.add(char);
            result += char;
        }
    }

    return result;
}


/**
 * Generates a random number of given range
 *
 * @param end - Number of end of the range
 * @param start - Number of start of the range
 * @returns The random integer
 */
export const pickRandomInt = (end: number, start: number = 0): number => {
    return Math.floor(Math.random() * (end - start + 1)) + start;
}


/**
 * Capitalizes the given string
 *
 * @param str - The target string
 * @returns The capitalized string
 */
export const capitalizeString = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}


/**
 * Generates password
 *
 * @param options - Password generation options
 * @returns The password
 */
export const generatePassword = (options: PasswordGenerationOption): string => {

    // config
    const maxiWordNumber = 4;
    const maxiNumberDigit = 6;

    // gets options
    let { wordNumber, numberPosition, numberDigit, splitMode } = options;

    // verifies
    if (wordNumber < 0) {
        wordNumber = 0;
    } else if (wordNumber > maxiWordNumber) {
        wordNumber = maxiWordNumber;
    }
    if (numberDigit < 0) {
        numberDigit = 0;
    } else if (numberDigit > maxiNumberDigit) {
        numberDigit = maxiNumberDigit;
    }

    // gets maximum value of list index
    const maxiBipIndex = bipList.length - 1;
    const maxiSymbolIndex = symbolList.length - 1;

    // gets using symbol
    const useSymbolList = splitMode.ignore ? symbolList.filter(smybol => !splitMode.ignore?.includes(smybol)) : symbolList;
    const maxiUseSymbolIndex = useSymbolList.length - 1;
    const specifySymbol = (splitMode.specify ? splitMode.specify : useSymbolList[pickRandomInt(maxiUseSymbolIndex)]) ?? symbolList[pickRandomInt(maxiSymbolIndex)];

    // maximum of number
    const maxiNumber = 10 ** numberDigit - 1;

    // generates words
    const words: Array<string> = [];
    for (let i = 0; i < wordNumber; i++) {
        words.push(bipList[pickRandomInt(maxiBipIndex)]);
    }

    // to records result
    let password: string;

    // not using split
    if (!splitMode.enabled || !useSymbolList.length) {

        // capitalizes all words when not split
        for (let i = 0; i < words.length; i++) {
            words[i] = capitalizeString(words[i]);
        }
        password = words.join('');

        // adds number
        const number = `${pickRandomInt(maxiNumber)}`.padStart(numberDigit, '0');
        switch (numberPosition) {
            case PasswordNumberPosition.start:
                return `${number}${symbolList[pickRandomInt(maxiSymbolIndex)]}${password}`;
            case PasswordNumberPosition.end:
                return `${password}${symbolList[pickRandomInt(maxiSymbolIndex)]}${number}`;
        }
    }

    // using split
    else {

        // capitalizes at least one words when split
        const toCapitals: Array<number> = zeroArray(words.length);
        const noCapitalIndexs = toCapitals.map((toCapital, index) => !toCapital ? index : -1).filter(noCapitalIndex => noCapitalIndex !== -1);
        const CapitalCount = pickRandomInt(wordNumber, 1);
        for (let i = 0; i < CapitalCount; i++) {
            const maxiNoCapitalIndexsIndex = noCapitalIndexs.length - 1;
            const toCapitalIndex = noCapitalIndexs[pickRandomInt(maxiNoCapitalIndexsIndex)];

            // sets capital word
            words[toCapitalIndex] = capitalizeString(words[toCapitalIndex]);

            // removes index from no capital index list
            noCapitalIndexs.splice(toCapitalIndex, 1);
        }

        // using specify split symbol
        if (splitMode.specify || splitMode.same) {
            password = words.join(specifySymbol);
        }

        // using fully random split symbol
        else {
            password = '';
            words.forEach((word, index) => {
                password += word;

                // adds split symbol if it is not the last word
                if (index !== words.length - 1) {
                    password += useSymbolList[pickRandomInt(maxiUseSymbolIndex)];
                }
            });
        }

        // adds number
        const number = `${pickRandomInt(maxiNumber)}`.padStart(numberDigit, '0');
        switch (numberPosition) {
            case PasswordNumberPosition.start:
                return `${number}${specifySymbol}${password}`;
            case PasswordNumberPosition.end:
                return `${password}${specifySymbol}${number}`;
        }
    }
}
