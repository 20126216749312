import config from 'src/config.json';
import {
    NavbarTitle,
    PasswordNumberPosition,
    PasswordSplitMode,
    PasswordGenerationOption,
    PasswordGeneratePageNavbarProps
} from 'src/index.d';
import { useState } from 'react';
import {
    AppBar,
    Toolbar,
    Box,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    TextField,
    ToggleButtonGroup,
    ToggleButton,
    Divider,
    FormControlLabel,
    Switch
} from '@mui/material';
import {
    Key,
    Tune
} from '@mui/icons-material';
import {
    removeRedundantInputSpace,
    removeRepetitiveCharacter
} from 'src/lib/lib';


const PasswordGenerateNavbar = (props: PasswordGeneratePageNavbarProps) => {

    // gets properties
    const { options, refresh, setOption, setPasswords } = props;

    // uses states
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
    // const [passwordLength, setPasswordLength] = useState<string>(`${options.length}`);
    const [wordNumber, setWordNumber] = useState<string>(`${options.wordNumber}`);
    const [numberDigit, setNumberDigit] = useState<string>(`${options.numberDigit}`);
    const [numberPosition, setNumberPosition] = useState<number>(options.numberPosition);
    const [splitEnabled, setSplitEnabled] = useState<boolean>(options.splitMode.enabled);
    const [splitSpecify, setSplitSpecify] = useState<string | undefined>(options.splitMode.specify);
    const [splitSame, setSplitSame] = useState<boolean | undefined>(options.splitMode.same);
    const [splitIgnore, setSplitIgnore] = useState<string | undefined>(options.splitMode.ignore);

    // handle open event of menu
    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setMenuAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    // handle close event of menu
    const handleMenuClose = () => {
        setMenuOpen(false);
    };

    //! not using length config
    // handle change of word length input
    // const handlePasswordLengthInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    //     const passwordLengthInput = event.target.value;
    //
    //     if (passwordLengthInput === '') {
    //         return;
    //     }
    //
    //     const newOptions: PasswordGenerationOption = {
    //         ...options,
    //         length: parseInt(passwordLengthInput)
    //     };

    //     setPasswordLength(passwordLengthInput);
    //     setOption(newOptions);

    //     // refreshs password list by new options
    //     setPasswords(refresh({ options: newOptions }));
    // };

    // handle change of word number input
    const handleWordNumberInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let newWordNumberInput = event.target.value;

        // empty
        if (newWordNumberInput === '') {
            setWordNumber('');
            return;
        }

        // range fixs
        if (parseInt(newWordNumberInput) < config.miniWordNumber) {
            newWordNumberInput = `${config.miniWordNumber}`;
        } else if (parseInt(newWordNumberInput) > config.maxiWordNumber) {
            newWordNumberInput = `${config.maxiWordNumber}`;
        }

        // updates when changed
        if (parseInt(newWordNumberInput) !== options.wordNumber) {
            const newOptions: PasswordGenerationOption = {
                ...options,
                wordNumber: parseInt(newWordNumberInput)
            };

            setOption(newOptions);

            // loacl storage
            localStorage.setItem('options', JSON.stringify(newOptions));

            // refreshs password list by new options
            setPasswords(refresh({ options: newOptions }));
        }

        setWordNumber(newWordNumberInput);
    };

    // handle change of number digit input
    const handleNumberDigitInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let newNumberDigitInput = event.target.value;

        // empty
        if (newNumberDigitInput === '') {
            setNumberDigit('');
            return;
        }

        // range fixs
        if (parseInt(newNumberDigitInput) < config.miniNumberDigit) {
            newNumberDigitInput = `${config.miniNumberDigit}`;
        } else if (parseInt(newNumberDigitInput) > config.maxiNumberDigit) {
            newNumberDigitInput = `${config.maxiNumberDigit}`;
        }

        if (parseInt(newNumberDigitInput) !== options.numberDigit) {
            const newOptions: PasswordGenerationOption = {
                ...options,
                numberDigit: parseInt(newNumberDigitInput)
            };

            setOption(newOptions);

            // loacl storage
            localStorage.setItem('options', JSON.stringify(newOptions));

            // refreshs password list by new options
            setPasswords(refresh({ options: newOptions }));
        }

        setNumberDigit(newNumberDigitInput);
    };

    // handle change of number position input
    const handleNumberPositionInputChange = (event: React.MouseEvent<HTMLElement>, newNumberPositingInput: number): void => {
        if (newNumberPositingInput === null) {
            return;
        }

        const newOptions: PasswordGenerationOption = {
            ...options,
            numberPosition: newNumberPositingInput
        };

        setNumberPosition(newNumberPositingInput);
        setOption(newOptions);

        // loacl storage
        localStorage.setItem('options', JSON.stringify(newOptions));

        // refreshs password list by new options
        setPasswords(refresh({ options: newOptions }));
    };

    // handle change of specify enabled input
    const handleSpecifyEnabledChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newEnabledInput = event.target.checked;

        const newSplitMode: PasswordSplitMode = {
            ...options.splitMode,
            enabled: newEnabledInput
        };
        const newOptions: PasswordGenerationOption = {
            ...options,
            splitMode: newSplitMode
        };

        setSplitEnabled(newEnabledInput);
        setOption(newOptions);

        // loacl storage
        localStorage.setItem('options', JSON.stringify(newOptions));

        // refreshs password list by new options
        setPasswords(refresh({ options: newOptions }));
    };

    // handle change of specify split input
    const handleSpecifySplitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newSpecifyInput = event.target.value.length ? event.target.value : undefined;

        if (newSpecifyInput && newSpecifyInput.length > config.maxiSpecifySplitCharacter) {
            return;
        }

        const newSplitMode: PasswordSplitMode = {
            ...options.splitMode,
            specify: newSpecifyInput
        };
        const newOptions: PasswordGenerationOption = {
            ...options,
            splitMode: newSplitMode
        };

        setSplitSpecify(newSpecifyInput);
        setOption(newOptions);

        // loacl storage
        localStorage.setItem('options', JSON.stringify(newOptions));

        // refreshs password list by new options
        setPasswords(refresh({ options: newOptions }));
    };

    // handle change of specify same input
    const handleSpecifySameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newSameInput = event.target.checked ?? undefined;

        const newSplitMode: PasswordSplitMode = {
            ...options.splitMode,
            same: newSameInput
        };
        const newOptions: PasswordGenerationOption = {
            ...options,
            splitMode: newSplitMode
        };

        setSplitSame(newSameInput);
        setOption(newOptions);

        // loacl storage
        localStorage.setItem('options', JSON.stringify(newOptions));

        // refreshs password list by new options
        setPasswords(refresh({ options: newOptions }));
    };

    // handle change of ignore split input
    const handleIgnoreSplitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const oldIgnore = options.splitMode.ignore ?? [''];
        const newIgnoreInput = event.target.value.length ? removeRepetitiveCharacter(removeRedundantInputSpace(event.target.value)) : undefined;
        const newIgnore = newIgnoreInput ?? [''];

        const newSplitMode: PasswordSplitMode = {
            ...options.splitMode,
            ignore: newIgnoreInput
        };
        const newOptions: PasswordGenerationOption = {
            ...options,
            splitMode: newSplitMode
        };

        const hasChange = oldIgnore !== newIgnore;

        setSplitIgnore(newIgnoreInput);
        setOption(newOptions);

        // loacl storage
        localStorage.setItem('options', JSON.stringify(newOptions));

        // refreshs password list by new options
        if (hasChange) {
            setPasswords(refresh({ options: newOptions }));
        }
    };

    // default menu width
    const menuWidth = 200;

    return (
        <AppBar
            component="nav"
            className="navbar"
            position="sticky"
        >
            <Toolbar sx={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <Key sx={{ mr: 1 }} />

                    <Typography
                        component="h1"
                        variant="h5"
                        fontWeight="bold"
                    >
                        {NavbarTitle.pw}
                    </Typography>
                </Box>
                <IconButton
                    sx={{ color: 'white' }}
                    onClick={handleMenuOpen}
                >
                    <Tune />
                </IconButton>
                <Menu
                    anchorEl={menuAnchorEl}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {/* <MenuItem>
                        <TextField
                            label="密碼個數"
                            placeholder="1~10"
                            value={passwordLength}
                            InputProps={{
                                inputProps: {
                                    type: 'number',
                                    min: 1,
                                    max: 10
                                },
                            }}
                            onChange={handlePasswordLengthInputChange}
                        />
                    </MenuItem> */}
                    <MenuItem>
                        <TextField
                            label="單字個數"
                            placeholder="1~4"
                            value={wordNumber}
                            InputProps={{
                                inputProps: {
                                    type: 'number',
                                    min: config.miniWordNumber,
                                    max: config.maxiWordNumber,
                                    onKeyPress: (event) => {
                                        if (event.key === '-') {
                                            event.preventDefault();
                                        }
                                    }
                                },
                            }}
                            onChange={handleWordNumberInputChange}
                            sx={{
                                width: `${menuWidth}px`,
                                maxWidth: `${menuWidth}px`
                            }}
                        />
                    </MenuItem>
                    <MenuItem>
                        <TextField
                            label="數字位數"
                            placeholder="1~6"
                            value={numberDigit}
                            InputProps={{
                                inputProps: {
                                    type: 'number',
                                    min: config.miniNumberDigit,
                                    max: config.maxiNumberDigit,
                                    onKeyPress: (event) => {
                                        if (event.key === '-') {
                                            event.preventDefault();
                                        }
                                    }
                                },
                            }}
                            onChange={handleNumberDigitInputChange}
                            sx={{
                                width: `${menuWidth}px`,
                                maxWidth: `${menuWidth}px`
                            }}
                        />
                    </MenuItem>
                    <MenuItem>
                        <ToggleButtonGroup
                            value={numberPosition}
                            exclusive
                            onChange={handleNumberPositionInputChange}
                            aria-label="number position"
                        >
                            <ToggleButton
                                value={PasswordNumberPosition.start}
                                key={PasswordNumberPosition.start}
                                sx={{
                                    width: `${menuWidth / 2}px`,
                                    maxWidth: `${menuWidth / 2}px`
                                }}
                            >
                                <Typography variant="button">
                                    以數字開頭
                                </Typography>
                            </ToggleButton>
                            <ToggleButton
                                value={PasswordNumberPosition.end}
                                key={PasswordNumberPosition.end}
                                sx={{
                                    width: `${menuWidth / 2}px`,
                                    maxWidth: `${menuWidth / 2}px`
                                }}
                            >
                                <Typography variant="button">
                                    以數字結尾
                                </Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </MenuItem>
                    <Divider />
                    <MenuItem>
                        <FormControlLabel
                            control={
                                <Switch
                                    size="small"
                                    checked={splitEnabled}
                                    onChange={handleSpecifyEnabledChange}
                                />
                            }
                            label="啟用分隔符"
                        />
                    </MenuItem>
                    {
                        splitEnabled && !splitIgnore ?
                            <MenuItem>
                                <TextField
                                    label="指定分隔符"
                                    placeholder={`最大 ${config.maxiSpecifySplitCharacter} 字元`}
                                    value={splitSpecify}
                                    onChange={handleSpecifySplitChange}
                                    sx={{
                                        width: `${menuWidth}px`,
                                        maxWidth: `${menuWidth}px`
                                    }}
                                />
                            </MenuItem> :
                            null
                    }
                    {splitEnabled && !splitSpecify && !splitIgnore ? <Divider /> : null}
                    {
                        splitEnabled && !splitSpecify ?
                            <MenuItem>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size="small"
                                            checked={splitSame}
                                            onChange={handleSpecifySameChange}
                                        />
                                    }
                                    label="分隔符一致"
                                />
                            </MenuItem> :
                            null
                    }
                    {
                        splitEnabled && !splitSpecify ?
                            <MenuItem>
                                <TextField
                                    label="禁用特定分隔符"
                                    placeholder="以空格區分"
                                    value={splitIgnore}
                                    onChange={handleIgnoreSplitChange}
                                    sx={{
                                        width: `${menuWidth}px`,
                                        maxWidth: `${menuWidth}px`
                                    }}
                                />
                            </MenuItem> :
                            null
                    }
                </Menu>
            </Toolbar>
        </AppBar >
    );
}

export default PasswordGenerateNavbar;
