/**
 * Enumeration of navbar title
 */
export enum NavbarTitle {
    pw = '密碼生成器'
}


/**
 * Enumeration of password number position
 */
export enum PasswordNumberPosition {
    start = 0,
    end = 1
};


/**
 * Type of refresh password list event function properties
 */
export type RefreshPasswordListEventProps = {
    passwords: Array<string>,
    index: number,
    options?: PasswordGenerationOption
} | {
    passwords?: Array<string>,
    index?: number,
    options: PasswordGenerationOption
}


/**
 * Type of refresh password list event function
 */
export type RefreshPasswordListEvent = (target?: RefreshPasswordListEventProps) => Array<string>;


/**
 * Type of password split mode
 */
export type PasswordSplitMode = {
    enabled: boolean,
    specify?: string,
    ignore?: string,
    same?: boolean
};


/**
 * Type of password generation option
 */
export type PasswordGenerationOption = {
    length: number,
    wordNumber: number,
    numberPosition: PasswordNumberPosition,
    numberDigit: PositiveInteger,
    splitMode: PasswordSplitMode
};


/**
 * Type of navbar properties
 */
export type PasswordGeneratePageNavbarProps = {
    options: PasswordGenerationOption,
    refresh: RefreshPasswordListEvent,
    setOption: React.Dispatch<React.SetStateAction<PasswordGenerationOption>>,
    setPasswords: React.Dispatch<React.SetStateAction<Array<string>>>
};


/**
 * Type of password snack bar state
 */
export type PasswordSnackbarState = {
    open: boolean,
    password?: string
};


/**
 * Type of password list properties
 */
export type PasswordListProps = {
    passwords: Array<string>,
    refresh: RefreshPasswordListEvent,
    setPasswords: React.Dispatch<React.SetStateAction<Array<string>>>
};


/**
 * Type of password row properties
 */
export type PasswordRowProps = {
    index: number,
    passwords: Array<string>,
    refresh: RefreshPasswordListEvent,
    setPasswords: React.Dispatch<React.SetStateAction<Array<string>>>,
    setSnackbarState: React.Dispatch<React.SetStateAction<PasswordSnackbarState>>
};
