import config from 'src/config.json';
import './PasswordGeneratePage.css';
import {
    RefreshPasswordListEventProps,
    RefreshPasswordListEvent,
    PasswordGenerationOption
} from 'src/index.d';
import {
    useState,
    useEffect
} from 'react';
import {
    Box,
    Paper
} from '@mui/material';
import PasswordGenerateNarbar from 'src/layouts/PasswordGenerateNavbar/PasswordGenerateNavbar';
import PasswordList from 'src/components/PasswordList/PasswordList';
import { generatePassword } from 'src/lib/lib';


const PasswordGeneratePage = () => {

    const localOptions = localStorage.getItem('options');

    let defaultOptions: PasswordGenerationOption;

    if (localOptions) {
        defaultOptions = JSON.parse(localOptions);
    } else {
        defaultOptions = {
            length: config.passwordsLength,
            wordNumber: config.defaultWordNumber,
            numberPosition: config.defalutNumberPosition,
            numberDigit: config.defalutNumberDigit,
            splitMode: {
                enabled: config.defaultEnableSplit
            }
        };
    }

    // uses states
    const [options, setOption] = useState<PasswordGenerationOption>(defaultOptions);

    // refreshs event
    const refresh: RefreshPasswordListEvent = (target?: RefreshPasswordListEventProps): Array<string> => {

        // refreshs target password row
        if (target) {
            if (target.passwords && target.index !== undefined) {

                const { passwords, index } = target;
                if (target.options) {
                    passwords[index] = generatePassword(target.options);
                } else {
                    passwords[index] = generatePassword(options);
                }

                return passwords;
            }

            // refresh all password rows by given options
            else if (target.options) {
                const passwords: Array<string> = [];
                for (let i = 0; i < target.options.length; i++) {
                    passwords.push(generatePassword(target.options));
                }

                return passwords;
            }
        }

        // refresh all password rows
        const passwords: Array<string> = [];
        for (let i = 0; i < options.length; i++) {
            passwords.push(generatePassword(options));
        }

        return passwords;
    }

    const [passwords, setPasswords] = useState(refresh());

    return (
        <Paper
            className="password-generate-page"
            elevation={3}
            sx={{
                maxWidth: '640px',
                margin: 'auto',
                minHeight: '100vh',
            }}
        >
            <PasswordGenerateNarbar
                options={options}
                refresh={refresh}
                setOption={setOption}
                setPasswords={setPasswords}
            />
            <Box
                className="page-cotent"
                sx={{
                    m: 0,
                    p: 0,
                    mt: '10px',
                    px: '10px'
                }}
            >
                <PasswordList
                    passwords={passwords}
                    refresh={refresh}
                    setPasswords={setPasswords}
                />
            </Box>
        </Paper>
    );
}


export default PasswordGeneratePage;
