import { PasswordRowProps } from 'src/index.d';
import { useState } from 'react';
import {
    Button,
    Box,
    Typography,
    IconButton
} from '@mui/material';
import { Refresh } from '@mui/icons-material';


const PasswordRow = (props: PasswordRowProps) => {

    // gets properties
    const { index, passwords, refresh, setPasswords, setSnackbarState } = props;

    // uses states
    const [password, setPassword] = useState(passwords[index]);

    // click refresh event
    const clickRefresh = (): void => {
        setPasswords(refresh({ passwords, index }));
        setPassword(passwords[index]);
    }

    // click copy event
    const clickCopy = (): void => {
        setSnackbarState({ open: true, password: passwords[index] });
        navigator.clipboard.writeText(passwords[index]);
        setPasswords(refresh({ passwords, index }));
        setPassword(passwords[index]);
    }

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        }}>
            <Button
                fullWidth
                onClick={clickCopy}
                sx={{ justifyContent: 'flex-start' }}
            >
                <Typography
                    textTransform="none"
                    textAlign="left"
                    noWrap
                    sx={{
                        color: 'black',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word'
                    }}
                >
                    {passwords[index]}
                </Typography>
            </Button>

            <IconButton
                color="secondary"
                onClick={clickRefresh}
            >
                <Refresh />
            </IconButton>
        </Box>
    );
}


export default PasswordRow;
