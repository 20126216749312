import { Box } from '@mui/material';
import PasswordGeneratePage from 'src/pages/PasswordGeneratePage/PasswordGeneratePage'


const App = () => {
    return (
        <Box className="app">
            <PasswordGeneratePage />
        </Box>
    );
}


export default App;
